import * as Yup from 'yup';
import { yupToFormErrors } from 'utils/Utils';
import { decamelize } from 'utils/keysConverter';
import { Option } from 'components/IntegrationsPanel/Integrations/components/ConfigurationTableModal/types';

const CanvasConfigurationSchema = Yup.object().shape({
  integrationType: Yup.string().label('Canvas Config').required(),
  authLink: Yup.string().label('Auth Link').nullable(),
  authRequired: Yup.string().label('Auth Required'),
  configuration: Yup.string().when('integrationType', {
    is: value => ['lti_11_configuration', 'lti_13_configuration'].includes(value),
    then: schema =>
      schema.required(value => {
        if (value === 'lti_11_configuration') {
          return 'XML Config is required';
        }
        return 'LTI Config is required';
      }),
    otherwise: schema => schema.notRequired(),
  }),
  url: Yup.string().when('integrationType', {
    is: value => value === 'lti_13_url' || value === 'lti_13_dynamic_registration' || value === 'lti_11_url',
    then: schema =>
      schema.required(value => {
        if (value === 'lti_11_configuration') {
          return 'Config URL is required';
        }
        return 'URL is required';
      }),
    otherwise: schema => schema.notRequired(),
  }),
  ltiPlacementsIds: Yup.array().label('Placements'),
  name: Yup.string().when('integrationType', {
    is: value => value === 'lti_11_configuration' || value === 'lti_11_url',
    then: schema => schema.required('Name is required'),
    otherwise: schema => schema.notRequired(),
  }),
  ltiServicesIds: Yup.array().label('Services'),
  keyName: Yup.string().when('integrationType', {
    is: value => value === 'lti_13_configuration' || value === 'lti_13_url',
    then: schema => schema.required('Key Name is required'),
    otherwise: schema => schema.notRequired(),
  }),
  ownerEmail: Yup.string()
    .email('Invalid email')
    .when('integrationType', {
      is: 'lti_13_url',
      then: schema => schema.required('Owner Email is required'),
      otherwise: schema => schema.notRequired(),
    }),
  globalInheritedKey: Yup.string().when('integrationType', {
    is: 'lti_13_global_inherited_key',
    then: schema => schema.required('Client ID is Required'),
    otherwise: schema => schema.notRequired(),
  }),
  notes: Yup.string().label('Notes').nullable(),
});

export default {
  defaultAttributes(form) {
    return {
      ...form,
    };
  },
  validate(form) {
    try {
      CanvasConfigurationSchema.validateSync(form, { abortEarly: false });
      return {};
    } catch (err) {
      return yupToFormErrors(err);
    }
  },

  attributesToSubmit(attributes) {
    const filteredForm = Object.entries(attributes).reduce((acc, [key, value]) => {
      if (value !== null && !(Array.isArray(value) && value.length === 0)) {
        if (Array.isArray(value)) {
          acc[key] = value.map((v: Option) => v.id);
        } else {
          acc[key] = value;
        }
      } else if (Array.isArray(value)) {
        acc[key] = [];
      } else {
        acc[key] = '';
      }
      return acc;
    }, {});
    return decamelize({ ...filteredForm });
  },
};
